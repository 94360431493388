// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import TaskAllocator from "../../blocks/TaskAllocator/src/TaskAllocator";
import TaskViews from "../../blocks/TaskViews/src/TaskViews";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import InventoryTrendAnalysis from "../../blocks/InventoryTrendAnalysis/src/InventoryTrendAnalysis";
import Testimonials from "../../blocks/Testimonials/src/Testimonials";
import Download from "../../blocks/Download/src/Download";
import SubscriptionBilling2 from "../../blocks/SubscriptionBilling2/src/SubscriptionBilling2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Payments from "../../blocks/Payments/src/Payments";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import Customform from "../../blocks/customform/src/Customform";
import OfflineWork from "../../blocks/OfflineWork/src/OfflineWork";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Addresses from "../../blocks/addressmanagement/src/Addresses";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import Analytics from "../../blocks/analytics/src/Analytics";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import InventoryManagement2 from "../../blocks/InventoryManagement2/src/InventoryManagement2";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import Prioritise from "../../blocks/Prioritise/src/Prioritise";
import TermsAndConditions3 from "../../blocks/TermsAndConditions3/src/TermsAndConditions3";
import ProjectTaskTracking from "../../blocks/ProjectTaskTracking/src/ProjectTaskTracking";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import InteractiveCalculator2 from "../../blocks/InteractiveCalculator2/src/InteractiveCalculator2";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import Location from "../../blocks/location/src/Location";
import MultipageForms2 from "../../blocks/MultipageForms2/src/MultipageForms2";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import PremiumPlanComparison from "../../blocks/PremiumPlanComparison/src/PremiumPlanComparison";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import TimeTracker from "../../blocks/TimeTracker/src/TimeTracker";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import ProjectNotes from "../../blocks/ProjectNotes/src/ProjectNotes";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
TaskAllocator:{
 component:TaskAllocator,
path:"/TaskAllocator"},
TaskViews:{
 component:TaskViews,
path:"/TaskViews"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
InventoryTrendAnalysis:{
 component:InventoryTrendAnalysis,
path:"/InventoryTrendAnalysis"},
Testimonials:{
 component:Testimonials,
path:"/Testimonials"},
Download:{
 component:Download,
path:"/Download"},
SubscriptionBilling2:{
 component:SubscriptionBilling2,
path:"/SubscriptionBilling2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Payments:{
 component:Payments,
path:"/Payments"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
PaymentAdmin:{
 component:PaymentAdmin,
path:"/PaymentAdmin"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
Customform:{
 component:Customform,
path:"/Customform"},
OfflineWork:{
 component:OfflineWork,
path:"/OfflineWork"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Addresses:{
 component:Addresses,
path:"/Addresses"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
InventoryManagement2:{
 component:InventoryManagement2,
path:"/InventoryManagement2"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription"},
Prioritise:{
 component:Prioritise,
path:"/Prioritise"},
TermsAndConditions3:{
 component:TermsAndConditions3,
path:"/TermsAndConditions3"},
ProjectTaskTracking:{
 component:ProjectTaskTracking,
path:"/ProjectTaskTracking"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
InteractiveCalculator2:{
 component:InteractiveCalculator2,
path:"/InteractiveCalculator2"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
Location:{
 component:Location,
path:"/Location"},
MultipageForms2:{
 component:MultipageForms2,
path:"/MultipageForms2"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
PremiumPlanComparison:{
 component:PremiumPlanComparison,
path:"/PremiumPlanComparison"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},
FileAttachment:{
 component:FileAttachment,
path:"/FileAttachment"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
TimeTracker:{
 component:TimeTracker,
path:"/TimeTracker"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
ProjectNotes:{
 component:ProjectNotes,
path:"/ProjectNotes"},

  Home: {
component:LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
